<template>
  <div id="chart">
    <vue-apex-charts type="line" width="100%" height="100%" :options="chartOptions" :series="chartData"></vue-apex-charts>
  </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts"

export default {
  name: "ApexLineChart",
  components: {
    VueApexCharts
  },
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    }
  }
}
</script>
